import React from "react";
// Sections
import TobBarEmpty from "../components/Nav/TobBarEmpty";
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer"

import Events from "../components/Sections/Events";
import LeContact from "../components/Sections/Contact";



export default function Landing() {
  return (
    <>
      <TobBarEmpty />
      <Header />
      <Events />
      <LeContact />
      <Footer />
    </>
  );
}


