import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Vers un metavers utile.</h1>
          <HeaderP className="font13 semiBold">
            Nous proposons un parc culturel virtuel comme un parc d'attraction. Entrez dans des documentaires immersifs, des expositions comme jamais vous n'en avez vécus avec les plus grand artistes...
          </HeaderP>
          <a href="#events">
            <BtnWrapper>
              <FullButton title="Voir nos évènements" />
            </BtnWrapper>
          </a>
        </div>
      </LeftSide>
      <RightSide>
       
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  backkground-color: #000;
  display: bloc;
    @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;