import React from "react";

function Section({ children }) {
  return <section id="contact" className="bg-white text-gray-100">{children}</section>;
}

function Container({ children }) {
  return <div className="container contactfor">{children}</div>;
}




export default function LeContact() {
  return (
  <Section>
    <h2 className="text-5xl font-bold text-center">Nous contacter</h2>
   <Container>
    <h2 className="text-10xl font-bold text-center">contact@omegapark.live</h2>
  </Container>
</Section>
  );
}
