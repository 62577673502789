import React from "react";
import audelaimage from "../../assets/img/ADP_image.png";
import FullButton from "../Buttons/FullButton";


function Section({ children }) {
  return <section id="events" className="bg-white text-gray-100">{children}</section>;
}

function Container({ children }) {
  return <div className="my-32 container columns-2 flex flex-col-reverse lg:flex-row">{children}</div>;
}

function LeftContent() {
  return (
    <div className="flex flex-col w-30 bg-violet-400 text-gray-900">
      <ContentItem title="Monstres, héros et dieux à visage humains, l'exposition" description="Une expérience autour de la mythologie grecque présentant un ensemble d'œuvres majeures de peintres ou sculpteurs de la Renaissance dans un décor immersif des enfers antiques."  />
      <ContentItem title="Salon du livre jeunesse" description="Faites découvrir l'amour de littérature à vos proches, en parcourant les stands des éditeurs du groupe Hachette et découvrez de nombreux extraits de roman, manga et livres illustrés."/>
      <ContentItem title="L’iceberg, le documentaire immersif" description="Une expérience autour de la banquise qui permettra de sensibiliser les visiteurs à l’impact de la fonte des glaciers et aux enjeux de la préservation des derniers sanctuaires sauvages, illustrée par des décors à ciel ouvert et la présence d’espèces polaires en danger." />
      <a href="https://audeladespages.live">
     <FullButton title="Visiter l'évènement" />
     </a>
    </div>
  );
}

function ContentItem({title, description}) {
  return (
    <div className="flex">
 
      <div className="space-y-2">
        <p className="text-lg font-bold ">{title}</p>
        <p className="leading-snug">{description}</p>
      </div>
    </div>
  );
}

function RightContent({ imgsrc}) {
  return (
    <div className="flex flex-col  w-4/5 bg-gray-800">
      <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
        <img src={imgsrc} alt="audeladespages" className="rounded-lg shadow-lg bg-gray-500 aspect-video sm:min-h-96" />
      </div>
    </div>
  );
}


export default function Events() {
  return (
  <Section>
    <h2 className="text-5xl font-bold text-center">Nos évènements</h2>
   <Container>
    <LeftContent />
    <RightContent imgsrc={audelaimage} />
  </Container>
</Section>
  );
}
