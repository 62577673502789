import * as React from "react";
import logo from "../img/logo.png";

function logocomp(props, invertPercent) {
  return (
    <img width="50px" alt="omegapark" src={logo}></img>
  );
}

export default logocomp;
